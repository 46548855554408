<template>
  <el-dialog class="bulkImport" :close-on-click-modal="false" :before-close="handleClose" title="文物清单导入"
    :visible.sync="visible" width="70%">

    <el-steps class="m_b2" :active="bringVolActive" finish-status="success">
      <el-step title="上传文件"></el-step>
      <el-step title="数据预览"></el-step>
      <el-step title="导入数据"></el-step>
      <el-step title="导入完成"></el-step>
    </el-steps>

    <!--上传文件-->
    <div v-if="bringVolActive == 0">
      <div v-if="!showAbnormal">
        <div class="flex_l_c b_d m_t1">
          <img src="../../../../../assets/img/zipIcon.png" class="zipIcon b_r">
          <div class="uploadInfo">
            <div class="f_w">上传文物图片压缩文件</div>
            <div class="tips">请上传文物图片压缩文件，文件格式为rar，zip，压缩文件内每个图片均需填写好对应文物名</div>
            <Upload @getPercentage="getPercentage" v-if="visible" :uploadAskObj="uploadRequire"
              @getDataList="getDataList"></Upload>
            <!-- <el-button type="primary" v-bind:disabled="this.uploadRequire.disabled" id="browse_button_zip">上传文件</el-button> -->
            <el-progress v-if="percentageFlage" style="margin-top: 5px" :stroke-width="10"
              :percentage="percentage"></el-progress>
          </div>
        </div>
        <div class="flex_l_c b_d m_t1">
          <img src="../../../../../assets/img/xlsIcon.png" class="xlsIcon b_r">
          <div class="uploadInfo">
            <div class="f_w">上传填好的信息表</div>
            <div class="tips">文件后缀名必须为xls或xlsx (即Excel格式) ，文件大小不得大于10M，最多支持导入3000条数据</div>
            <div class="flex_l_c">
              <Upload @getPercentage1="getPercentage1" class="m_r1" v-if="visible" :uploadAskObj="uploadRequire2"
                @getDataList="getDataList2"></Upload>
              <!-- <el-button type="primary" v-bind:disabled="this.uploadRequire2.disabled" id="browse_button_excel">上传文件</el-button> -->
              <el-button size="small" type="primary" @click="downloadRow">下载模板</el-button>
            </div>
            <div>
              <el-progress v-if="percentageFlage1" style="margin-top: 5px" :stroke-width="10"
                :percentage="percentage1"></el-progress>

            </div>
          </div>
        </div>
      </div>
      <!--上传成功后展示-->
      <div v-else>
        <div class="flex_l_c b_d m_t1 m_b2">
          <img src="../../../../../assets/img/abnormalIcon.png" class="zipIcon b_r">
          <div class="uploadInfo">
            <div class="m_b1"><span class="f_w">正常数量条数: </span><span class="greenColor">{{ trueNumber }}</span></div>
            <div><span class="f_w">异常数量条数: </span><span class="redColor">{{ flaseNumber }}</span></div>
          </div>
        </div>
        <div class="b_d p_all1 abnormalTips" v-if="errorMsg != null">
          <div class="f_w m_b1 ">异常提示：</div>
          <div class="m_b1 bgclore">异常数据将不会被导入</div>
          <div v-for="(item, index) in errorList" :key="index" class="m_b1 tips">
            <div class="m_b1 bgclore" v-if="item.delFlag === 1">文物名称为【{{ item.culturalName }}】excel表中有必填数据为空or对应图片没上传
            </div>
            <div class="m_b1 bgclore" v-if="item.delFlag === 2">文物名称为【{{ item.culturalName }}】excel表中数据长度超出限制</div>

          </div>
        </div>
      </div>
    </div>
    <!--数据预览-->
    <div v-if="bringVolActive == 1">
      <el-table :data="fileList" size="small" height="calc(100vh - 420px)" class="table">
        <el-table-column type="index" label="顺序号" :index="indexMethod" width="110"></el-table-column>
        <el-table-column prop="name" label="文物照片">
          <template slot-scope="scope">
            <el-image style="width: 60px; height: 60px" :src="scope.row.url" fit="contain"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="culturalName" label="文物名称" show-overflow-tooltip />
        <el-table-column prop="times" label="时代" sortable show-overflow-tooltip />
        <el-table-column prop="size" label="尺寸" show-overflow-tooltip />
        <el-table-column prop="collectionLocation" label="收藏地点" show-overflow-tooltip />
        <el-table-column prop="fieldName3" label="藏品号" show-overflow-tooltip />
        <el-table-column prop="fieldName4" label="文物标识" show-overflow-tooltip />
        <el-table-column prop="fieldName8" label="文件类型" show-overflow-tooltip />
        <el-table-column prop="unearthedSite" label="出土地点" show-overflow-tooltip />
        <el-table-column prop="remarks" label="备注" show-overflow-tooltip />
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :total="fileList.length"
        background layout="total">
      </el-pagination>
      <!-- :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize" -->
    </div>
    <!--导入数据-->
    <div v-if="bringVolActive == 2" class="importData">
      <el-progress :percentage="importProgress" :stroke-width="20"></el-progress>
      <div class="text_center importTips">{{ zhagntaiWenzi }}</div>
    </div>
    <!--导入完成-->
    <div v-if="bringVolActive == 3" class="importData text_center">
      <div class="el-icon-circle-check greenColor"></div>
      <div class="f_w m_b1 title">数据导入完成</div>
      <div class="tips">您已成功导入{{ trueNumber }}条数据</div>
      <el-button size="small" type="primary" @click="handleClose()">返回</el-button>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" plain v-if="bringVolActive == 0 && showAbnormal"
        @click="reUpload()">重新上传</el-button>
      <el-button size="small" type="primary" plain v-if="bringVolActive > 0 && bringVolActive < 2"
        @click="prev()">上一步</el-button>
      <el-button size="small" type="primary" @click="next()" v-if="bringVolActive != 3">下一步</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload/MyUpload"; // 导入上传组件
import Uploader from "@/components/Upload/Uploader"; // 导入上传组件

export default {
  components: { Upload, Uploader },
  data() {
    return {
      visible: false,
      exhibitionOpt: [],
      exhibitionVal: '',
      bringVolActive: 0,
      fileStatus: false,
      percentage: 0,
      percentageFlage: false,
      percentage1: 0,
      percentageFlage1: false,
      //上传要求
      uploadRequire: {
        list: [],
        btnName: '上传文件', // 按钮名称
        megaNum: 500, // 上传文件兆数 0表示不限兆数
        count: 1, // 上传文件数量 0表示不限制文件的数量
        fileType: ['rar', 'zip'],
        uploadPath: 'cultural',
        disabled: true,
        uploadUrl: this.$globalUrl() + 'zyd-common/file/exhibitionUpload?uploadPath=cultural',
        percentageMethod: 'getPercentage',
      },
      uploadRequire2: {
        list: [],
        btnName: '上传文件', // 按钮名称
        megaNum: 10, // 上传文件兆数 0表示不限兆数
        count: 1, // 上传文件数量 0表示不限制文件的数量
        fileType: ['xls', 'xlsx'],
        uploadPath: '',
        uploadUrl: '',
        disabled: true,
        percentageMethod: 'getPercentage1',
      },
      showAbnormal: false, // true 展示上传后的异常提示
      // 数据预览
      fileList: [],
      errorList: [],
      trueNumber: 0,
      flaseNumber: 0,
      errorMsg: '',
      flag: false,
      excelUrl: '',
      importProgress: 1, //导入进度
      zhagntaiWenzi: '正在导入数据',
      listKey: 'excelList1',
      listErrarKey: 'excelList',
      up: {},
      files: [],
      up_excel: {},
      files_excel: [],
      file_excel: {},
      myHeaders: { Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token') },
      myfilters_zip: {
        mime_types: [{ title: "Zip files", extensions: "zip,rar" }],
        max_file_size: '10MB', //最大只能上传400kb的文件
        prevent_duplicates: false //不允许选取重复文件},
      },
      myfilters_excel: {
        mime_types: [{ title: "Excel files", extensions: "xls,xlsx" }],
        max_file_size: '10MB', //最大只能上传400kb的文件
        prevent_duplicates: false //不允许选取重复文件},
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
    }
  },
  computed: {
    dateRange() {
      const { exhibitionVal, fileStatus, flag } = this;
      return { exhibitionVal, fileStatus, flag }
    }
  },
  watch: {
    dateRange(val) {
      if (val.exhibitionVal != '') {
        this.uploadRequire.disabled = false;
        // this.uploadRequire2.disabled = false;
      }
      if (val.fileStatus && val.flag) {
        this.showAbnormal = true;
        this.$axios('zyd-catalogue/commonCulturalRelics/importExcel' + "?exhibitionId=" + this.exhibitionVal + "&&uploadPath=1", {}, 'post').then(data => {
          const a = data.data;
          this.errorMsg = a.errorMsg;
          this.trueNumber = a.trueNumber;
          this.flaseNumber = a.flaseNumber;
        });
      }
      // if(val.fileStatus){
      //     this.showAbnormal=true;
      // }
    }
  },
  methods: {
    init(exhibitionId) {
      this.exhibitionVal = exhibitionId;
      this.uploadRequire2.uploadUrl = this.$globalUrl() + 'zyd-catalogue/commonCulturalRelics/importExcel' + "?exhibitionId=" + this.exhibitionVal;
      // this.getCatalogue();
      this.visible = true;
      this.showAbnormal = false
      this.refreshList();
      // this.up.removeFile(this.file_excel);

    },
    reUpload() {
      this.percentage = 0;
      this.bringVolActive = 0;
      this.percentageFlage = false;
      this.percentage1 = 0;
      this.percentageFlage1 = false;
      this.uploadRequire.disabled = true;
      this.uploadRequire2.disabled = true;
      this.showAbnormal = false;
      this.fileStatus = false;
      this.flag = false;
    },
    // tableRowClassName ({row,rowIndex}) {
    //     if (row.delFlag==1) {
    //         this.$refs.tTable.setCurrentRow(row, true);
    //         return 'warning-row';
    //     }
    //     return '';
    // },

    indexMethod(index) {
      let curpage = this.pageNo; //单前页码，具体看组件取值
      let limitpage = this.pageSize; //每页条数，具体是组件取值
      return index + 1 + (curpage - 1) * limitpage;
    },
    // 查询展览
    // getCatalogue() {
    //     this.$axios(this.api.zlzs.queryExhibition, {
    //         'name':"",
    //         'pageNo': this.pageNo,
    //         'pageSize': this.pageSize,}, 'get').then(data => {
    //         if (data && data.status) {

    //             this.exhibitionOpt = data.data.records
    //         }
    //     })
    // },
    // 收集整编查询
    refreshList() {
      this.loading = true
      this.$axios(this.api.zlzs.fileCollectList, {
        'name': 'book1',
        "delFlag": 0,
        'pageNo': this.pageNo,
        'pageSize': this.pageSize,
      }, 'get').then(data => {
        if (data && data.status) {
          this.excelUrl = data.data.records[0].url
        }
      });
    },
    // 下载
    downloadRow() {
      const a = document.createElement('a')
      //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
      if (this.excelUrl != "" && (this.excelUrl != null || this.excelUrl != undefined)) {
        const url = this.excelUrl.substring(this.excelUrl.indexOf("\\"), this.excelUrl.length); // 完整的url则直接使用
        // 这里是将url转成blob地址，
        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob)
          a.download = "模板" // 下载文件的名字
          // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
          document.body.appendChild(a)
          a.click()
        })
      } else {
        this.$message.warning("暂无模板,请联系管理员。")
      }
    },
    // 上一步
    prev() {
      if (this.bringVolActive > 0) {
        this.bringVolActive--
      }
    },
    // 下一步
    next() {
      if (this.bringVolActive == 2 && this.importProgress != 100) {
        this.$message("请等待文件导入完成")
      } else if (this.bringVolActive == 3) {
        this.visible = false;
        this.bringVolActive = 0;
        this.handleClose();
      } else {
        if (this.fileStatus && this.flag) {
          this.bringVolActive++;
          if (this.bringVolActive == 1) {
            // this.previewHeader();
            this.previewData();
          } else if (this.bringVolActive == 2) {
            this.executeImport()
          }
        } else {

          if (!this.flag) {
            this.$message("请上传压缩文件")
          } else if (!this.fileStatus) {
            this.$message("请上传excel文件")
          }

        }
      }
    },
    // 获取上传数据
    getDataList(data) {
      if (data.state == 0) {
        this.percentage = 100;
        this.$message(data.data[0].name + "上传完成")
        this.flag = true;
        this.uploadRequire.disabled = true;
        this.uploadRequire2.disabled = false;
      } else {
        this.$message(data.data[0].name + "上传失败")
      }
    },
    // 获取上传进度
    getPercentage(data) {
      this.percentageFlage = true;
      if (parseInt(data.percentage) >= 99) {
        this.percentage = 99
      } else {
        this.percentage = parseInt(data.percentage);
      }
    },
    getDataList2(data) {
      if (data.state == 0) {
        this.percentage1 = 100;
        const a = data.data[0].url;
        this.$message(data.data[0].name + "上传完成")
        this.trueNumber = a.trueNumber;
        this.flaseNumber = a.flaseNumber;
        this.errorMsg = a.errorMsg
        if (a.culturalRelicsList != null || a.culturalRelicsList != undefined) {
          this.fileList = a.culturalRelicsList;
        }
        if (a.culturalRelicsErrorList != null || a.culturalRelicsErrorList != undefined) {
          this.errorList = a.culturalRelicsErrorList;
        }
        if (this.percentage1 == 100) {
          this.fileStatus = true;
          this.uploadRequire2.disabled = true;
        }

      } else {
        this.$message(data.data[0].name + "上传失败")
      }
    },
    // 获取上传进度
    getPercentage1(data) {
      this.percentageFlage1 = true;
      // if(parseInt(data.percentage)>=99){
      //     this.percentage1=99
      // }else{
      //     this.percentage1=parseInt(data.percentage);
      // }
      let timer = setInterval(() => {
        let situation = '';
        situation = Math.floor(Math.random() * (0 - 10) + 10);
        this.percentage1 += situation;
        if (this.percentage1 > 80) {
          clearInterval(timer)
        }
      }, 500)
    },
    //执行导入
    executeImport() {
      //导入进度加载(网速慢)
      let timer = setInterval(() => {
        let situation = '';
        situation = Math.floor(Math.random() * (0 - 10) + 10);
        this.importProgress += situation;
        if (this.importProgress > 80) {
          clearInterval(timer)
        }
      }, 500)
      //导入请求
      this.$axios(this.api.zlzs.culturalRelicsExcelSave + '?listKey=' + this.listKey, {}, 'post').then(res => {
        if (res.status) {
          clearInterval(timer)
          this.importProgress = 100;
          this.zhagntaiWenzi = '数据导入成功'
          this.total = res.data
        } else {
          clearInterval(timer)
          this.zhaungStatus = 'exception';
          this.zhagntaiWenzi = '数据导入失败'
        }
      })
    },

    //获取临时预览数据
    previewData() {
      this.$axios(this.api.zlzs.culturalRelicsExcelList, {
        listKey: this.listErrarKey,
        current: this.pageNo,
        size: this.pageSize,
      }, 'get').then(res => {
        if (res.status) {
          this.fileList = JSON.parse(res.data);
        }
      })
    },
    inputUploader(up) {
      this.up = up;
      this.files = up.files;
    },
    filesAdded(up, files) {
      this.uploading = true;
      //模拟点击上传
      let aa = setTimeout(() => {
        this.uploadStart();
      }, 1000)
    },

    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.previewData();

    },
    currentChangeHandle(val) {
      this.pageNo = val;
      this.previewData();

    },
    //弹窗关闭
    handleClose() {
      this.percentageFlage = false;
      this.percentage = 0;
      this.percentageFlage1 = false;
      this.percentage1 = 0;
      this.fileStatus = false;
      this.visible = false;
      this.showAbnormal = false;
      this.bringVolActive = 0;
      this.uploadRequire.disabled = true;
      this.uploadRequire2.disabled = true;
      this.exhibitionVal = ''
      this.importProgress = 0;
      this.zhagntaiWenzi = '正在导入数据'
      //上传成功后，刷新列表
      this.$emit('refreshDataList')
    },
  }
}
</script>

<style scoped>
.bgclore {
  font-size: 200%;
  color: brown;
}

.el-table__body-wrapper>>>.el-table__row.warning-row {
  background: rgb(209, 145, 25) !important;
}
</style>
