<template>
  <div class="page bg-white" id="reorganize">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="lump">
          <div class="catalogueBox">
            <div class="flex_l_c">
              <div class="f_w">展览：</div>
              <el-input class="flex1" maxlength="66" v-model="catalogueSrh" placeholder="请输入展览名称" size="small"
                clearable></el-input>
            </div>
            <el-table :data="catalogueList" ref="catalogueTable" size="small" height="calc(100vh - 268px)" class="table"
              highlight-current-row @current-change="handleCurrentChange">
              <el-table-column prop="exhibitionName" label="展览" show-overflow-tooltip />
            </el-table>
            <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
              :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>
      </el-col>
      <el-col :span="18">
        <div class="lump">
          <div class="flex_b_c headBox">
            <div class="flex_l_c">
              <el-input class="flex1 m_r1" maxlength="66" v-model="fileSrh" placeholder="请输入文物名称" size="small"
                clearable></el-input>
              <el-button icon="el-icon-search" type="primary" size="small" @click="searchFileList">查询</el-button>
            </div>
            <div>
              <el-button size="small" type="success" v-if="hasPermission('cultural:archive')" plain
                @click.native="archive()" :disabled="dataListSelections.length <= 0">归档</el-button>
              <el-button size="small" type="success" v-if="hasPermission('cultural:batchArchive')" plain
                @click.native="batchArchive()" :disabled="exhibitionId == '' || fileList.length == 0">一键归档</el-button>
              <!-- <el-button icon="el-icon-document" size="small" plain @click="getLog()">日志</el-button> -->
            </div>
          </div>
          <div class="catalogueBox">
            <div class="flex_b_c">
              <div class="f_w">文物清单</div>
              <div>
                <el-button icon="el-icon-plus" size="small" type="primary" :disabled="exhibitionId == ''"
                  @click="view('add', null)">新建</el-button>
                <el-dropdown class="m_l1">
                  <el-button type="primary" size="small">
                    智能操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="importData()" :disabled="exhibitionId == ''">批量导入</el-dropdown-item>
                    <el-dropdown-item @click.native="download()"
                      :disabled="dataListSelections.length <= 0">下载</el-dropdown-item>
                    <el-dropdown-item @click.native="edit('move')"
                      :disabled="dataListSelections.length <= 0">移动</el-dropdown-item>
                    <el-dropdown-item @click.native="edit('edit')"
                      :disabled="dataListSelections.length <= 0">修改</el-dropdown-item>
                    <el-dropdown-item @click.native="del()"
                      :disabled="dataListSelections.length <= 0">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button-group class="m_l1">
                  <el-button size="small" plain icon="el-icon-refresh" @click="getFileList"></el-button>
                  <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                </el-button-group>
              </div>
            </div>
            <el-table :data="fileList" size="small" @selection-change="selectionChangeHandle" v-loading="loading"
              :element-loading-text="txt" element-loading-spinner="el-icon-loading" height="calc(100vh - 320px)"
              class="table table3" ref="tableBox" @row-click="rowClick">
              <el-table-column type="selection" width="50" fixed />
              <!--                            <el-table-column prop="sequenceNumber" sortable label="顺序号" width="110">-->
              <!--                                <template slot-scope="scope">-->
              <!--                                    <el-input-number style="width: 100px" size="mini" v-model="scope.row.sequenceNumber" @change="handleChangeOrder" :min="1"></el-input-number>-->
              <!--                                </template>-->
              <!--                            </el-table-column>-->
              <!--                            <el-table-column prop="name" label="文物照片">-->
              <!--                                <template slot-scope="scope">-->
              <!--                                    <el-image-->
              <!--                                            style="width: 60px; height: 60px"-->
              <!--                                            :src="scope.row.url"-->
              <!--                                            fit="contain"></el-image>-->
              <!--                                </template>-->
              <!--                            </el-table-column>-->
              <!--                            <el-table-column prop="culturalName" label="文物名称" show-overflow-tooltip />-->
              <!--                            <el-table-column prop="times" label="时代" sortable show-overflow-tooltip />-->
              <!--                            <el-table-column prop="unearthedSite" label="出土地点" show-overflow-tooltip />-->
              <!--                            <el-table-column prop="collectionLocation" label="收藏地点" show-overflow-tooltip />-->
              <!--                            -->
              <el-table-column v-for="(item, index) in config" :key="item.id" :prop="item.fieldEnname"
                :label="item.recordName"
                :show-overflow-tooltip="item.fieldEnname != 'sequenceNumber' && item.fieldEnname != 'url'"
                v-if="item.onShow == 0" :width="item.fieldEnname == 'sequenceNumber' ? 150 : ''">
                <template slot-scope="scope">
                  <el-input-number v-if="item.fieldEnname == 'sequenceNumber'" style="width: 100px" size="mini"
                    v-model="scope.row.sequenceNumber" @change="handleChangeOrder(scope.row)" :min="1" :max="999999"
                    v-noMoreClick></el-input-number>
                  <el-image v-else-if="item.fieldEnname == 'url'" style="width: 60px; height: 60px"
                    :src="scope.row.accessPath" fit="contain"></el-image>
                  <!--                                    <div v-else>{{scope.row[item.fieldEnname]}}</div>-->
                  <div
                    v-else-if="item.fieldEnname != 'sequenceNumber' && item.fieldEnname != 'url' && scope.row[item.fieldEnname]">
                    {{ scope.row[item.fieldEnname] }}</div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="200">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" plain @click="view('view', scope.row)">详情</el-button>
                  <Upload style="display: inline-block; margin-left: 10px" :uploadAskObj="uploadRequire"
                    @getDataList="getDataList"></Upload>
                  <el-dropdown class="m_l1">
                    <el-button plain size="mini">
                      更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="download(scope.row)">下载</el-dropdown-item>
                      <el-dropdown-item @click.native="edit('move', scope.row)">移动</el-dropdown-item>
                      <el-dropdown-item @click.native="edit('edit', scope.row)">修改</el-dropdown-item>
                      <el-dropdown-item @click.native="del(scope.row)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="sizeChangeHandle2" @current-change="currentChangeHandle2" :current-page="pageNo2"
              :page-sizes="[10, 20, 50, 100]" :page-size="pageSize2" :total="total2" background
              layout="total, prev, pager, next, sizes">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--列表设置拖拽排序弹窗-->
    <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
    <!-- 新建、详情 -->
    <FileDetails ref="fileDetails" @refreshDataList="getFileList"></FileDetails>
    <!-- 批量导入 -->
    <BulkImport ref="bulkImport" @refreshDataList="getFileList"></BulkImport>
    <!--修改、移动-->
    <EditForm ref="editForm" @refreshDataList="getFileList"></EditForm>
    <!--    日志    -->
    <Log ref="reorganizeLog"></Log>

    <getTemplate ref="getTemplate" @refreshDataList="getFileList"></getTemplate>
  </div>
</template>

<script>
import DraggablePop from '@/components/draggable/draggablePop' // 导入排序弹窗
import FileDetails from './fileDetails' // 新建、详情
import BulkImport from './bulkImport' // 批量导入
import Log from "../log"; // 日志
import EditForm from './editForm' // 修改
import getTemplate from "./getTemplate"; // 获取档号规则
import Upload from '@/components/Upload/Upload_zlzs' // 上传
export default {
  components: { DraggablePop, FileDetails, BulkImport, Log, EditForm, getTemplate, Upload },
  data() {
    return {
      visibleLog: false,
      // 展览
      catalogueSrh: '',
      catalogueList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      // 文物清单
      fileSrh: '',
      fileList: [],
      dataListSelections: [],
      loading: false,
      pageNo2: 1,
      pageSize2: 10,
      total2: 0,
      moduleId: 1,   // 当前请求拖拽排序数据id
      setShow: false, // 是否显示列表设置拖拽排序弹窗
      exhibitionId: "",
      log: {
        operModular: "3",//模块
        operType: "1",//类型
        operTerm: "",//内容
        operSystem: "1",//结果
      },
      config: [],
      rows: [],
      row: {},
      //上传要求
      uploadRequire: {
        btnName: '替换', // 按钮名称
        list: [],
        count: 1, // 上传文件数量 0表示不限制文件的数量
        fileType: ['png', 'bmp', 'jpg', 'jpeg', 'gif', 'PNG', 'BMP', 'JPG', 'JPEG', 'GIF'],
        uploadPath: "cultural",
      },
      fileVersionHistory: {
        fileId: "",
        url: "",
        fileFormat: "",
        fileSize: "",
        fileName: "",
        version: "1",
      },
      txt: "拼命加载中"
    }
  },
  watch: {
    // 实时输入查询目录
    catalogueSrh() {
      this.searchCatalogue();
    },
  },
  mounted() {
    this.getCatalogue();
    // this.getFileList();
    this.querySysOriginalDescriptionAll();
  },
  methods: {
    querySysOriginalDescriptionAll() {
      let that = this;
      this.$axios(this.api.zlzs.querySysOriginalDescriptionAll, {
        flag: 1,
        onDetails: 1
      }, 'get').then(data => {
        if (data) {
          this.config = data
          this.config.forEach(function (v) {
            v.fieldEnname = that.toHump(v.fieldEnname)
            if (v.fieldEnname == 'updateId') {
              v.fieldEnname = 'updateName'
            }
            if (v.fieldEnname == 'createId') {
              v.fieldEnname = 'createName'
            }
            // that.$set(that.inputForm,v.fieldEnname,"")
          })
          console.log("配置：", this.config);
        }
      })
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    // 新建
    view(method, row) {
      if (method == 'add' && this.exhibitionId == '') {
        this.$message('请选择展览');
        return;
      }
      this.$refs.fileDetails.init(method, row, this.exhibitionId, "collect")
    },
    // 批量导入
    importData() {
      this.$refs.bulkImport.init(this.exhibitionId)
    },
    // 文物清单表格多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    searchCatalogue() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.total = 0;
      this.exhibitionId = ""
      this.getCatalogue();
      this.fileList = [];
    },
    // 查询展览
    getCatalogue() {
      this.$axios(this.api.zlzs.queryExhibition, {
        'name': this.catalogueSrh,
        'pageNo': this.pageNo,
        'pageSize': this.pageSize,
      }, 'get').then(data => {
        if (data && data.status) {
          this.catalogueList = data.data.records
          this.total = parseInt(data.data.total)
        }
      })
    },
    // 点击展览行
    handleCurrentChange(val) {
      this.$refs.catalogueTable.setCurrentRow(val);
      this.exhibitionId = val.id
      this.pageNo2 = 1;
      this.getFileList()
    },
    searchFileList() {
      this.pageNo2 = 1;
      this.getFileList();
    },
    // 查询文物清单
    getFileList() {
      if (this.exhibitionId != "") {
        this.txt = "拼命加载中"
        this.loading = true
        this.$axios(this.api.zlzs.culturalRelicsList, {
          'exhibitionId': this.exhibitionId,
          "fileName": this.fileSrh,
          "archive": 0,
          'pageNo': this.pageNo2,
          'pageSize': this.pageSize2,
          'flag': 1,
          'onDetails': 1,
        }, 'get').then(data => {
          if (data && data.status) {
            this.fileList = data.data.records
            if (this.fileList.length == 0 && this.pageNo2 > 1) {
              this.pageNo2--
              this.getFileList()
            }
            this.rows = JSON.parse(JSON.stringify(data.data.records))
            this.total2 = parseInt(data.data.total)
            this.loading = false
            this.$refs.tableBox.doLayout();
          }
        })
      }
    },
    // 改变顺序号
    handleChangeOrder(row) {
      let oldValue = ''
      this.rows.forEach(function (v) {
        if (v.id == row.id) {
          oldValue = v.sequenceNumber
        }
      })
      if (row) {
        this.loading = true
        this.txt = "正在重新排列顺序号，请稍后~"
        this.log.operType = '9'
        this.log.operTerm = row.culturalName
        this.$axios(this.api.zlzs.culturalRelicsSetOrder, {
          id: row.id,
          sequenceNumber: row.sequenceNumber,
          oldSequenceNumber: oldValue,
          exhibitionId: this.exhibitionId
        }, 'put').then(data => {
          if (data.status) {
            this.$message.success("顺序号排列完成")
            this.log.operSystem = '0'
          } else {
            this.$message.error(data.msg)
            this.log.operSystem = '1'
          }
          // this.$axios(this.api.zlzs.logSave, this.log, 'post').then(data => {
            this.getFileList()
          // })
        })
      }
    },
    rowClick(currentRow) {
      // console.log(currentRow);
      // console.log(oldCurrentRow);
      this.row = currentRow;
      // console.log(this.row);
    },
    //替换 获取上传数据
    getDataList(data) {
      if (data.state == 0) {
        this.log.operType = '7';
        console.log(data);
        this.fileVersionHistory.fileId = this.row.id
        this.fileVersionHistory.url = data.data[0].url
        this.fileVersionHistory.fileFormat = data.data[0].type
        this.fileVersionHistory.fileSize = data.data[0].size
        this.fileVersionHistory.fileName = data.data[0].name
        this.$axios(this.api.zlzs.culturalVersionHistorySave, this.fileVersionHistory, 'post').then(data => {
          if (data.status) {
            this.$message.success("替换成功")
            this.log.operSystem = '0'
          } else {
            this.$message.error(data.msg)
            this.log.operSystem = '1'
          }
          this.logSave3(this.row, this.fileVersionHistory.fileName)
        })
        this.uploadRequire.list = [];
      }
    },
    // 下载
    download(row) {
      let rows = [];
      if (row) {
        rows = [row]
      } else {
        rows = this.dataListSelections
      }
      let that = this;
      this.log.operType = '19'
      this.log.operSystem = '1'
      console.log(rows);
      rows.forEach(function (v) {
        that.log.operTerm = v.culturalName
        const a = document.createElement('a')
        //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
        const url = v.accessPath;  // 完整的url则直接使用
        // 这里是将url转成blob地址，
        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob)
          console.log(a.href)
          a.download = v.culturalName // 下载文件的名字
          // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
          document.body.appendChild(a)
          a.click()
          that.log.operSystem = '0'
          that.$axios(that.api.common.logSave, that.log, 'post')
        })
      })
    },
    // 修改、移动、批量修改、批量移动
    edit(method, row) {
      let rows = [];
      if (row) {
        rows = [row]
      } else {
        rows = this.dataListSelections
      }
      this.$refs.editForm.init(method, rows, 'reorganize', this.config)
    },
    // 删除、批量删除
    del(row) {
      let ids = ''
      let sequenceNumbers = ""
      let exhibitionId = ''

      if (row) {
        ids = row.id
        sequenceNumbers = row.sequenceNumber
        exhibitionId = row.exhibitionId
      } else {
        ids = this.dataListSelections.map(item => {
          return item.id
        }).join(',')
        sequenceNumbers = this.dataListSelections.map(item => {
          return item.sequenceNumber
        }).join(',')
        this.dataListSelections.forEach(function (v) {
          exhibitionId = v.exhibitionId
        })
      }
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.txt = "正在重新排列顺序号，请稍后~"
        this.loading = true;
        this.$axios(this.api.zlzs.culturalRelicsBatchUpdateById, {
          'ids': ids,
          "delFlag": 1,
          "sequenceNumbers": sequenceNumbers,
          "exhibitionId": exhibitionId
        }, 'put').then(data => {
          this.log.operType = '1'
          if (data && data.status) {
            this.log.operSystem = '0'
            this.$message.success("顺序号排列完成，删除成功！")
          } else {
            this.$message.error(data.msg)
            this.log.operSystem = '1'
          }
          this.logSave(this.dataListSelections, row)
          this.loading = false
        })
      })
    },
    //归档
    archive() {
      let ids = this.dataListSelections.map(item => {
        return item.id
      }).join(',')
      this.$refs.getTemplate.init('archive', ids, this.dataListSelections)
      // this.$confirm(`确定归档所选项吗?`, '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      // }).then(() => {
      //     this.log.operType = '12'
      //     let ids = this.dataListSelections.map(item => {
      //         return item.id
      //     }).join(',')
      //     this.$axios(this.api.zlzs.culturalRelicsArchive, {
      //         'ids':ids,
      //     }, 'put').then(data => {
      //         if (data && data.status) {
      //             this.log.operSystem = '0'
      //             this.$message.success("已归档")
      //             // this.getFileList();
      //         }else{
      //             this.log.operSystem = '1'
      //         }
      //         this.logSave(this.dataListSelections);
      //     })
      // })
    },
    //一键归档
    batchArchive() {
      let that = this;
      let exhibitionName = '';
      this.catalogueList.forEach(function (v) {
        if (that.exhibitionId == v.id) {
          exhibitionName = v.exhibitionName + "下的所有清单"
        }
      })
      this.$refs.getTemplate.init('batchArchive', null, null, this.exhibitionId, exhibitionName)
      // this.$confirm(`确定归档目录下所有文档吗?`, '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      // }).then(() => {
      //     this.log.operType = '13'
      //     this.$axios(this.api.zlzs.culturalRelicsKeyArchive, {
      //         'exhibitionId':this.exhibitionId,
      //     }, 'put').then(data => {
      //         if (data && data.status) {
      //             this.log.operSystem = '0'
      //             this.$message.success("已归档")
      //             // this.getFileList();
      //         }else{
      //             this.log.operSystem = '1'
      //         }
      //         this.logSave2();
      //     })
      // })
    },
    //一键归档，新增日志
    logSave2() {
      let that = this;
      this.catalogueList.forEach(function (v) {
        if (that.exhibitionId == v.id) {
          that.log.operTerm = v.exhibitionName + "下的所有档案"
        }
      })
      this.$axios(this.api.zlzs.batchInsertLogin, this.log, 'post').then(data => {
        this.getFileList()
      })
    },
    //新增日志
    logSave3(row, fileName) {
      if (row) {
        // console.log("删除");
        this.log.operTerm = row.fileName + "【替换成】" + fileName
        // console.log(this.log);
        // this.$axios(this.api.zlzs.logSave, this.log, 'post').then(data => {
          // console.log("日志：",data);
          // if(data.status){
          this.getFileList()
          // }
        // })
      }
    },
    //新增日志
    logSave(dataListSelections, row) {
      if (row) {
        console.log("删除");
        this.log.operTerm = row.culturalName
        console.log(this.log);
        // this.$axios(this.api.zlzs.logSave, this.log, 'post').then(data => {
          // console.log("日志：",data);
          // if(data.status){
          this.getFileList()
          // }
        // })
      } else {
        let that = this;
        let batchList = []
        dataListSelections.forEach(function (v) {
          let log = JSON.parse(JSON.stringify(that.log))
          log.operTerm = v.culturalName
          batchList.push(log)
        })
        // this.$axios(this.api.zlzs.batchInsertLogin, batchList, 'post').then(data => {
          if (dataListSelections.length == this.fileList.length) {
            this.pageNo2 = 1
          }
          this.getFileList()
        // })
      }
    },
    //查看日志
    getLog() {
      this.$refs.reorganizeLog.init('3')
    },
    // 设置
    setTb() {
      this.setShow = true;
    },
    // 接收子组件（排序弹窗）传过来的值
    getTbList(data, state) {
      this.config = data;
      this.getFileList();
      this.setShow = state;
    },
    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getCatalogue();
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getCatalogue();
    },
    // 文物清单每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getFileList();
    },
    // 文物清单当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getFileList();
    },
  }
}
</script>

<style scoped>
.table3.el-table>>>.cell {
  text-overflow: initial !important;
}
</style>
